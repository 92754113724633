/* @media print {
	@page {
		size: A5;
		margin: 0;
	}
} */

* {
	box-sizing: border-box;
}

html {
	margin: 0px;
	/* From normalize */
	line-height: 1.15;
}

body {
	font-family: 'Open Sans', sans-serif;
	margin: 0px;
	padding: 0px;

	content-visibility: auto;
	background: #231f20;
}

label {
	margin-left: 5px;
}

ul {
	margin: 0;
	padding-inline-start: 12px;
}

li {
	margin-bottom: 6px;
}

a {
	text-decoration: none;
	color: inherit;
}

@media screen {
	a:hover {
		text-decoration: underline;
	}
}

@keyframes load {
	from {
			left: -150px;
	}
	to   {
			left: 100%;
	}
}

/* Normalize overrides */
[type=checkbox], [type=radio] {
	box-sizing: border-box;
	padding: 0;
}
button, input {
	overflow: visible;
}
button, input, optgroup, select, textarea {
	font-family: inherit;
	font-size: 100%;
	line-height: 1.15;
	margin: 0;
}